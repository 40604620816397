import loadable from '@loadable/component';
import TeamProfile from '../components/views/TeamProfile'; //This component does not work with the loadable

const Home = loadable(() => import('../components/views/Home/'));
const Login = loadable(() => import('../components/views/Login'));
const SignUp = loadable(() => import('../components/views/SignUp'));
const GetHelp = loadable(() => import('../components/views/GetHelp'));
const Advertise = loadable(() => import('../components/views/Advertise'));
const CommercialVenues = loadable(() =>
  import('../components/views/CommercialVenues'),
);
const SectionHome = loadable(() => import('../components/views/SectionHome'));
const Fixtures = loadable(() => import('../components/views/Fixtures'));
const Results = loadable(() => import('../components/views/Results'));
const SubmitTicket = loadable(() => import('../components/views/SubmitTicket'));
const Play = loadable(() => import('../components/views/Play'));
const PageNotFound = loadable(() => import('../components/views/PageNotFound'));
const ForgotPassword = loadable(() =>
  import('../components/views/ForgotPassword'),
);
const ResetPassword = loadable(() =>
  import('../components/views/ResetPassword'),
);
const ChangePassword = loadable(() =>
  import('../components/views/ChangePassword'),
);
const TablesView = loadable(() => import('../components/views/TablesView'));
const Category = loadable(() => import('../components/views/Category'));
const TeamsListing = loadable(() => import('../components/views/TeamsListing'));
const UnsupportedBrowser = loadable(() =>
  import('../components/ui/UnsupportedBrowser'),
);

const DownloadApp = loadable(() => import('../components/ui/DownloadApp'));
const CustomPage = loadable(() => import('../components/views/CustomPage'));
const Voucher = loadable(() => import('../components/views/Voucher'));
const ArticleDetail = loadable(() =>
  import('../components/views/ArticleDetail'),
);
const SignupPage = loadable(() => import('../pages/SignupPage'));
const SignupUserPage = loadable(() => import('../pages/SignupUserPage'));
const SettingsPage = loadable(() => import('../components/views/SettingsPage'));
const DeviceManageDetailsList = loadable(() =>
  import('../components/ui/DeviceManagement/DeviceManageDetailsList'),
);
const FitnessLanding = loadable(() =>
  import('../components/views/FitnessLanding'),
);
const UserCompetition = loadable(() =>
  import('../components/views/UserCompetition'),
);
const PaymentPage = loadable(() => import('../pages/PaymentPage'));
const PairDeviceContainer = loadable(() =>
  import('../components/views/PairDeviceContainer'),
);
const VendorPairDeviceContainer = loadable(() =>
  import('../components/views/VendorPairDeviceContainer'),
);
const SearchPage = loadable(() => import('../pages/SearchPage'));

const toTitleCase = (str) => str.replace(/(^|\s)\S/g, (t) => t.toUpperCase());

const generateCTVRoutes = (props = {}) => {
  const { getFeatureFlags = () => {} } = props;
  const ctvConfig = getFeatureFlags('CTV_CONFIG') || {};
  return Object.keys(ctvConfig).reduce((acc, val) => {
    const entryRoute = {
      name: `EntryCTV${toTitleCase(val)}`,
      component: VendorPairDeviceContainer,
      inSection: false,
      path: `/${val}`,
      params: { ctv: val },
      useOwnPageHeader: true,
    };
    const signupRoute = {
      name: `SignupCTV${toTitleCase(val)}`,
      component: SignupPage,
      inSection: false,
      path: `/register/${val}`,
      params: { ctv: val },
      useOwnPageHeader: true,
    };
    const checkoutRoute = {
      name: `CheckoutCTV${toTitleCase(val)}`,
      component: PaymentPage,
      inSection: false,
      path: `/checkout/${val}`,
      params: { ctv: val },
      useOwnPageHeader: true,
    };
    return [...acc, entryRoute, signupRoute, checkoutRoute];
  }, []);
};

const generateConnectRoute = (props = {}) => {
  const { getFeatureFlags = () => {} } = props;
  const connectPage = getFeatureFlags('FEATURE_CONNECT_PAGE');

  return connectPage
    ? [
        {
          name: 'PairDevice',
          component: PairDeviceContainer,
          inSection: false,
          path: '/connect',
          params: null,
          useOwnPageHeader: true,
        },
        {
          name: 'AppleSignupPage',
          component: SignupPage,
          inSection: false,
          path: '/appletv',
          params: { ctv: 'appletv' },
          useOwnPageHeader: true,
        },
      ]
    : [];
};

const generateExternalSignupRoute = (props = {}) => {
  const { getFeatureFlags = () => {} } = props;
  const extSignupPage = getFeatureFlags('FEATURE_EXTERNAL_SIGNUP_PAGE');

  return extSignupPage
    ? [
        {
          name: 'Register User',
          component: SignupUserPage,
          inSection: false,
          path: '/register/user',
          params: null,
          useOwnPageHeader: true,
        },
      ]
    : [];
};

const generateDeviceManagementRoute = (props = {}) => {
  const { getFeatureFlags = () => {} } = props;
  const deviceManagementEnabled = getFeatureFlags('FEATURE_DEVICE_MANAGEMENT');
  return deviceManagementEnabled
    ? [
        {
          name: 'UserSettingsDevices',
          component: DeviceManageDetailsList,
          inSection: false,
          path: '/usersettings/devices',
          params: null,
        },
      ]
    : [];
};

const generateEditProfileRoute = (props = {}) => {
  const { getFeatureFlags = () => {} } = props;
  const featureEditProfile = getFeatureFlags('FEATURE_EDIT_PROFILE');
  return featureEditProfile
    ? [
        {
          name: 'EditProfile',
          component: SettingsPage,
          inSection: false,
          path: '/usersettings/editprofile',
          params: null,
        },
      ]
    : [];
};

const generateCustomVoucherCampaignRoute = (props = {}) => {
  const { getFeatureFlags = () => {} } = props;
  const customCampaginEnabled = getFeatureFlags('CUSTOM_VOUCHER_CAMPAIGN');
  return customCampaginEnabled
    ? [
        {
          name: 'CustomPage',
          component: CustomPage,
          inSection: false,
          path: '/campaigns/:pageId',
          params: { pageType: 'CAMPAIGN' },
          useOwnPageHeader: true,
          useOwnPageFooter: false,
          hideSaagBar: true,
        },
      ]
    : [];
};

const generateSearchRoute = (props = {}) => {
  const { getFeatureFlags = () => {} } = props;
  const searchEnabled = getFeatureFlags('FEATURE_SEARCH');

  return searchEnabled
    ? [
        {
          name: 'Search',
          component: SearchPage,
          path: '/search',
          inSection: false,
          params: null,
        },
        {
          name: 'SearchDetail',
          component: SearchPage,
          path: '/search/:text',
          inSection: false,
          params: null,
        },
      ]
    : [];
};

const NewsArticleDetailPath = '/news/:category/:id/:slug';

// all views is routed with the following structure
const baseRoutesList = [
  /**/
  {
    name: 'HomePage',
    component: Home,
    inSection: false,
    path: '/',
    params: null,
  },
  {
    name: 'UserSettings',
    component: SettingsPage,
    inSection: false,
    path: '/usersettings',
    params: null,
  },
  {
    name: 'Login',
    component: Login,
    inSection: false,
    path: '/login',
    params: null,
  },
  {
    name: 'SignUp',
    component: SignUp,
    inSection: false,
    path: '/signup',
    params: null,
    useOwnPageHeader: true,
    useOwnPageFooter: true,
    hideSaagBar: true,
  },
  {
    name: 'GetHelp',
    component: GetHelp,
    inSection: false,
    path: '/gethelp',
    params: null,
    hideSaagBar: true,
  },
  {
    name: 'Advertise',
    component: Advertise,
    inSection: false,
    path: '/advertise',
    params: null,
    hideSaagBar: true,
  },
  {
    name: 'CommercialVenues',
    component: CommercialVenues,
    inSection: false,
    path: '/venues',
    params: null,
    hideSaagBar: true,
  },
  {
    name: 'ForgotPassword',
    component: ForgotPassword,
    inSection: false,
    path: '/forgotpassword',
    params: null,
  },
  {
    name: 'NewPassword',
    component: ForgotPassword,
    inSection: false,
    path: '/newpassword',
    params: null,
  },
  {
    name: 'ResetPassword',
    component: ResetPassword,
    inSection: false,
    path: '/resetpassword/:uniqueResetToken',
    params: null,
  },
  {
    name: 'ResetPasswordV2',
    component: ResetPassword,
    inSection: false,
    path: '/resetpassword/v2/:uniqueResetToken/:uniqueResetCode',
    params: null,
  },
  {
    name: 'ChangePassword',
    component: ChangePassword,
    inSection: false,
    path: '/changepassword',
    params: null,
  },
  {
    page: 'TeamProfile',
    component: TeamProfile,
    inSection: false,
    path: '/:id/teamProfile/:teamId',
    params: null,
  },
  {
    name: 'SubmitTicket',
    component: SubmitTicket,
    inSection: false,
    path: '/help',
    params: null,
  },
  {
    name: 'HelpSupport',
    component: Home,
    inSection: false,
    path: '/helpsupport',
    params: null,
  },
  {
    name: 'HelpSupportNotification',
    component: Home,
    inSection: false,
    path: '/support',
    params: null,
  },
  {
    name: 'Category',
    component: Category,
    inSection: false,
    path: '/category/:categoryId',
    params: null,
  },
  {
    name: 'SectionCategory',
    component: Category,
    inSection: true,
    path: '/category/:categoryId',
    params: null,
  },
  {
    name: 'PlayOpta',
    component: Play,
    inSection: true,
    path: '/play',
    params: null,
  },
  {
    name: 'Play',
    component: Play,
    inSection: true,
    path: '/play/:assetId',
    params: null,
  },
  {
    name: 'Videos',
    component: Play,
    inSection: true,
    path: '/videos/:assetId/:slug',
    params: null,
  },
  {
    name: 'VideosNoSection',
    component: Play,
    inSection: false,
    path: '/videos/:assetId/:slug',
    params: null,
  },
  {
    name: 'ArticleDetail',
    component: ArticleDetail,
    inSection: true,
    path: '/articles/:id/:slug',
    params: null,
  },
  {
    name: 'ArticleDetailNoSection',
    component: ArticleDetail,
    inSection: false,
    path: '/articles/:id/:slug',
    params: null,
  },
  {
    name: 'NewsArticleDetail',
    component: ArticleDetail,
    inSection: false,
    path: NewsArticleDetailPath,
    params: null,
    hideSaagBar: true,
  },
  {
    name: 'Fixtures',
    component: Fixtures,
    inSection: true,
    path: '/fixtures',
    params: null,
  },
  {
    name: 'TablesView',
    component: TablesView,
    inSection: true,
    path: '/table',
    params: null,
  },
  {
    name: 'Results',
    component: Results,
    inSection: true,
    path: '/results',
    params: null,
  },
  {
    name: 'TeamsListing',
    component: TeamsListing,
    inSection: false,
    path: '/teams',
    params: null,
  },
  {
    name: 'UnsupportedBrowser',
    component: UnsupportedBrowser,
    inSection: false,
    path: '/unsupported',
    params: null,
  },
  {
    name: 'DownloadApp',
    component: DownloadApp,
    inSection: false,
    path: '/downloadapp',
    params: null,
  },
  {
    name: 'Voucher',
    component: Voucher,
    inSection: false,
    path: '/campaign',
    params: null,
  },
  {
    name: 'Register',
    component: SignupPage,
    inSection: false,
    exact: false,
    path: '/register',
    params: null,
    useOwnPageHeader: true,
  },
  {
    name: 'FitnessHome',
    component: Home,
    inSection: false,
    path: '/fitness/:slug?',
    params: { path: 'fitness' },
    hideSaagBar: true,
  },
  {
    name: 'NewsArticlesHome',
    component: Home,
    inSection: false,
    path: '/news/:slug?',
    params: { path: 'news' },
  },
  {
    name: 'Checkout',
    component: PaymentPage,
    inSection: false,
    path: '/checkout',
    params: null,
    useOwnPageHeader: true,
  },
  {
    name: 'SectionHome',
    component: SectionHome,
    inSection: true,
    path: '/',
    params: null,
  },
  {
    name: 'FitnessLanding',
    component: FitnessLanding,
    inSection: false,
    path: '/fitnessonoptussport',
    params: null,
    useOwnPageHeader: true,
    useOwnPageFooter: true,
  },
  {
    name: 'UserCompetition',
    component: UserCompetition,
    inSection: false,
    path: '/competitions/:compId',
    params: null,
    useOwnPageHeader: true,
    useOwnPageFooter: false,
    hideSaagBar: true,
  },
  {
    name: 'PageNotFound',
    component: PageNotFound,
    inSection: false,
    path: '/404',
    params: null,
  },
  {
    name: 'PageNotFound',
    component: PageNotFound,
    inSection: false,
    path: '*',
    params: null,
  },
];

const routesList = (props) => [
  ...generateCTVRoutes(props),
  ...generateConnectRoute(props),
  ...generateExternalSignupRoute(props),
  ...generateSearchRoute(props),
  ...generateDeviceManagementRoute(props),
  ...generateEditProfileRoute(props),
  ...generateCustomVoucherCampaignRoute(props),
  ...baseRoutesList,
];

export { routesList, NewsArticleDetailPath };
